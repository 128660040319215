import "../styles/global.css";
import React, { useState } from 'react';

export default ({ children }) => {

  return (
    <div>
    	<header></header>
      <main className="mainContainer">
        {children}
      </main>
      <footer></footer>
    </div>
  )
}
